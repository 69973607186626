@font-face {
  font-family: 'Mukta';
  font-style: NORMAL;
  font-weight: 200;
  src: url("../fonts/Mukta/Mukta-ExtraLight.ttf") format('truetype');
}

@font-face {
  font-family: 'Mukta';
  font-style: NORMAL;
  font-weight: 300;
  src: url("../fonts/Mukta/Mukta-Light.ttf") format('truetype');
}





@font-face {
  font-family: 'IBM Plex Sans';
  font-style: NORMAL;
  font-weight: 300;
  src: url("../fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf") format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/IBM_Plex_Sans/IBMPlexSans-LightItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: NORMAL;
  font-weight: 400;
  src: url("../fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: BOLD;
  font-weight: 700;
  src: url("../fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: NORMAL;
  font-weight: 300;
  src: url("../fonts/Nunito_Sans/NunitoSans_10pt-Light.ttf") format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: BOLD;
  font-weight: 700;
  src: url("../fonts/Nunito_Sans/NunitoSans_10pt-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Nunito_Sans/NunitoSans_10pt-Italic.ttf") format('truetype');
}


