@function rhythm($multiplier){
    @return 1rem * $multiplier;
}

@function standard-margin(){
    @return rhythm(2)
}
  

@mixin grid-basics {
    display: grid;
    
    column-gap: standard-margin();
}

@mixin standard-grid {
    @include grid-basics;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    padding: 0 standard-margin();
    
    @media only screen and (max-width: 640px){ //phone (landscape)
        padding: 0;
        column-gap: 0;
    }
}
  