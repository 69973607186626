@import "scss_library";

body.post {

    h2{
        letter-spacing: unset;
        font-size: 2.25rem;
    }

    h3{
        letter-spacing: unset;
        font-size: 1.5rem;
    }

    p, ul, ol{
        font-size: 1.125rem; 
    }

    section.meta-data{
        background-color:hsl( var(--clr-blue-2));
        padding: standard-margin() 0;
        padding-bottom: 0;

        & > div {
            padding-bottom: 0;
        }

        div.author{
            grid-column: 4/6;
            font-weight: bold;
            font-size: rhythm(1.25);
            font-family: "Mukta"
        }

        div.date{
            grid-column: 7/10;
            text-align: right;
            grid-row: 1/2;
            text-transform: uppercase;
            // font-size: rhythm(1.25);
        }

        div.categories{
            grid-column: 4/6;
            text-decoration: underline;
        }

        @media only screen and (max-width: 1366px){
            div.author{
                grid-column: 3/6;
            }
            div.categories {
                grid-column: 3/6;
            }
            div.date{
                grid-column: 7/11;
            }
         }

         @media only screen and (max-width: 640px){ //phones
            div.author{
                grid-column: 3/11;
                grid-row: 1/2;
            }
            div.date{
                grid-column: 3/11;
                grid-row: 2/3;
                text-align: left;
            }
            div.categories {
                grid-column: 3/11;
                grid-row: 3/4;
            }
        }


    }

    section.post-introduction{
        padding-top: 0;
    }

    section.post-body{
        padding: standard-margin() 0;
      
        div.grid-container > div {
            grid-column: 2/9;
      
            @media only screen and (max-width: 1366px){ //phones
                grid-column: 3/11;
            }
            @media only screen and (max-width: 640px){ //phones
                grid-column: 2/12;
            }
        }
      }
}


section.blog-posts{
    padding: standard-margin() 0;
    div.grid-container{
        row-gap: standard-margin();
    }

    a{text-decoration: none}
}

section.blog-intro{
    div.grid-container{
        p{
            text-align: center;
            grid-column: 2/12
        }
        // row-gap: standard-margin();
    }
}

div.post.card{

        grid-column: 2/12;
        background: hsl( var(--clr-blue-1));
        a > div {
            @include grid-basics;
            grid-template-columns: repeat(10, minmax(0, 1fr));
            color: hsl( var(--clr-blue-6));
        }

        .image{
            grid-column: 1/5;
            grid-row: 1/4;
            overflow: hidden;
            position: relative;
            
            img{
                position: absolute;
                left: 50%;
                transform: translate(-50%,0)
            }
        }

        .title{
            padding: rhythm(1);
            padding-left: 0;
            padding-bottom: 0;
            grid-column: 5/11;
            font-family: "Mukta";
            font-size: rhythm(1.5);
        }

        .description{
            grid-column: 5/11;
            padding-right: standard-margin();
            text-decoration: none;
        }

        .date{
            grid-column: 5/11;
        }

        @media only screen and (max-width: 640px){ 
            .image{
                grid-column: 1/11;
                img{
                    position: relative;
                    width: 100%;
                }
            }
            .title, .description, .date{
                grid-column: 1/11;
                padding: 0 standard-margin();
            }
            .title{
                padding-top: calc(standard-margin() / 2)
            }
        }
    }





