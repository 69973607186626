
@import "scss_library";

// Spacing classes
.mt-0{
  margin-top: 0
}

.mb-0{
  margin-bottom: 0
}


// List classes
.inline-list{
    text-align: center;
    letter-spacing: rhythm(0.25);
    font-size: rhythm(0.85);
    padding: 0 calc( standard-margin() / 2);
    li{
        list-style: none;
        display: inline;
        &:before {
            content: " | "
        }
        &:nth-child(1):before{
            content: none;
        }
    }
}

.centered-list{
  text-align: center;
  padding-inline-start: 0px;
  margin: 0px;
  list-style-type: none;
}