@import "scss_library";

.wordmark{
    display: flex;
    flex-direction: column;
    justify-content: center;


    background: hsl( var(--clr-orange-3));
    color: hsl( var(--clr-blue-1));
    padding: 0 1rem;
    
    font-family: "Mukta";
    font-weight: 300;
    font-size: 2rem;
    line-height: 3rem;
    letter-spacing: 0.5rem;
    font-variant: small-caps;
}

.pull-quote{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    aspect-ratio: 1/1;
    font-family: 'Mukta';
    font-weight: 200;
    font-size: rhythm(1.25);
    padding: rhythm(1);
    line-height: 1.5;

    text-transform: uppercase;
    font-variant: small-caps;
}


section.section{
    padding: standard-margin() 0;
    div.grid-container > div {
        @include grid-basics;
        
        grid-column: 2/12;

        // h3{
        //   margin-bottom: calc(standard-margin() / 5);
        //   padding-bottom: calc(standard-margin() / 5);
        // }
    }

}

section#my-purpose{
    
    div.grid-container > div {
        grid-column: 3/11;
      

        .clear{           
            content: '';
            display: block;
            clear: both;
        }

        .pull-quote{
            float: right;
            width: 21.5%;
            max-width: 180px;
            background-color: hsl( var(--clr-blue-4));
            margin-top: calc(standard-margin() / 2);
            margin-left: standard-margin();
            margin-bottom: standard-margin();
        }

        .big-quote{
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            font-family: 'Mukta';
            font-weight: 200;
            font-size: rhythm(2);
            padding: rhythm(2);
            // padding-top:0;
            text-transform: lowercase;
            font-variant: small-caps;

            .title{
                letter-spacing: rhythm(0.2);
            }

            .content{
                letter-spacing: rhythm(0.5);
            }


            float: left;
            background-color: hsl( var(--clr-blue-1));
            // border: 1px solid black;
            aspect-ratio: 1/1;
            color: black;
            width: 48%;
            max-width: 320px;
            margin-right: standard-margin();
            margin-bottom: standard-margin();
            // margin-top: standard-margin();
        }

        @media only screen and (max-width: 1366px){ //phones
            grid-column: 2/12;
        }
        @media only screen and (max-width: 768px){ //phones
            grid-column: 2/12;
            @include standard-grid;
            grid-template-columns: repeat(10, minmax(0, 1fr));

            h2, .pull-quote, p, .big-quote {
                grid-column: 1/11;
            }
            .pull-quote{
                aspect-ratio: 5/1;       
                width: unset;
                max-width: unset;
                margin: standard-margin();
            }
            .big-quote{
                grid-column: 1/11;
                margin: calc(standard-margin() / 2 ) auto;
            }
        }
        @media only screen and (max-width: 640px){ //phones
            .big-quote{
                h2, p.content{
                font-size: rhythm(1.5);
                }
            }
        }
       
    }
}

section#my-values{
    padding: standard-margin() 0;


    div.grid-container > div {
        @include grid-basics;
        grid-column: 3/11;
        grid-template-columns: repeat(8, minmax(0, 1fr));

        @media only screen and (max-width: 1366px){ //phones
            grid-column: 2/12;
            grid-template-columns: repeat(10, minmax(0, 1fr));
        }


        h2{
            grid-column: 1/9;
            @media only screen and (max-width: 1366px){ //phones
                grid-column: 1/11;
            }
            // text-align: right;
        }

        #value-quotes{
            grid-column: 1/4;
            @media only screen and (max-width: 1366px){ 
                grid-column: 1/5;
            }
            @media only screen and (max-width: 768px){ //phones
                grid-column: 1/11;
            }

            .pull-quote{
                aspect-ratio: 4/1;
                background-color: hsl( var(--clr-orange-5));
                color: hsl( var(--clr-blue-1));
                 margin-top: calc(standard-margin() / 2) ;
                 

            }
        }
        #value-intro {
            grid-column: 4/9;
            @media only screen and (max-width: 1366px){ //phones
                grid-column: 5/11;
            }
            @media only screen and (max-width: 768px){ //phones
                grid-column: 1/11;
            }

        }




        .value{
            grid-column: 1/9;
            @include grid-basics;
            grid-template-columns: repeat(8, minmax(0, 1fr));
            @media only screen and (max-width: 1366px){ //phones
                grid-column: 1/11;
                grid-template-columns: repeat(10, minmax(0, 1fr));
            }


            h3{
                grid-column: 1/9;
                @media only screen and (max-width: 1366px){ 
                    grid-column: 1/11;
                }
            }

            div.banner-image{
                grid-column: 1/9;
                @media only screen and (max-width: 1366px){ 
                    grid-column: 1/11;
                }
                margin-bottom: calc(standard-margin() / 2);
            }

            blockquote {
                font-style: italic;
            }


            .pull-quote{
                grid-row: 3/5;
                aspect-ratio: 2/1;
                
            }

            &.curiosity{
                .pull-quote{
                    background-color: hsl( var(--clr-orange-4));
                    color: hsl( var(--clr-blue-1));
                }
            }

            &.sustainability{
                .pull-quote{
                    background-color: hsl( var(--clr-blue-2));
                }
            }
            &.integrity{
                .pull-quote{
                    background-color: hsl( var(--clr-orange-6));
                    color: hsl( var(--clr-blue-1));
                }
            }
        }

        .value:nth-child(even){
            blockquote { 
                grid-column: 1/6; 
                @media only screen and (max-width: 1366px){ 
                    grid-column: 1/11;
                }
            }
            .behaviours { 
                grid-column: 1/6; 
                @media only screen and (max-width: 1366px){ 
                    grid-column: 1/11;
                }
            }
            .pull-quote { 
                grid-column: 6/9; 
                @media only screen and (max-width: 1366px){ 
                    grid-column: 1/11;
                    aspect-ratio: 6/1;
                }
            }
        }

        .value:nth-child(odd){
            blockquote { 
                grid-column: 4/9; 
                @media only screen and (max-width: 1366px){ 
                    grid-column: 1/11;
                }
            }
            .behaviours { 
                grid-column: 4/9; 
                @media only screen and (max-width: 1366px){ 
                    grid-column: 1/11;
                }
            }
            .pull-quote { 
                grid-column: 1/4; 
                @media only screen and (max-width: 1366px){ 
                    grid-column: 1/11;
                    aspect-ratio: 6/1;
                }
            }
        }

    }
}

section#working-models {
    div.grid-container{
        p {
            grid-column: 2/12;
        }

        div#deliver{
            grid-column: 2/12;
        }

        div#support {
            grid-column: 2/7;
        }

        div#advise {
            grid-column: 7/12;
        }

        @media only screen and (max-width: 640px){ //phone (landscape)

            div#deliver{
              grid-column: 1/13;   
              margin-bottom: 0;
              margin-top: standard-margin();
            }
        
            div#support{
              grid-column: 1/13;
            }
        
            div#advise{
              grid-column: 1/13;
            }
          }
    } 
}

section#freelance-projects {
    div.grid-container{
        row-gap: standard-margin();

        div:nth-child(1){
            grid-column: 1/13;
            @media only screen and (max-width: 640px){
                padding: 0 standard-margin();
            }
                
        }


    }

    .project-card{
        background-color:hsl( var(--clr-blue-2));
        color: hsl( var(--clr-blue-6));
        padding: standard-margin();
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        

        h3{
            grid-column: 1/3;
        
        }

        p {
            grid-column: 1/3;
        }

        img {
            grid-column: 3/4;
            grid-row: 1/3;
            width: 85%;
            margin: auto 0;
            padding: 0 standard-margin();
        }


        &:nth-child(even){
            grid-column: 1/7;
        }
        &:nth-child(odd){
            grid-column: 7/13;
        }

        @media only screen and (max-width: 768px){
            &:nth-child(even){
                grid-column: 2/12;
            }
            &:nth-child(odd){
                grid-column: 2/12;
            }
        }
        @media only screen and (max-width: 640px){
            img{
                padding: 0;
                grid-column: 1/4;
                grid-row: unset;
            }
            p {
                grid-column: 1/4;
            }
            h3{
                grid-column: 1/4;
            }
            &:nth-child(even){
                grid-column: 1/13;
            }
            &:nth-child(odd){
                grid-column: 1/13;
            }
        }
    }

}

section#case-studies{
    padding: standard-margin() 0;
    @media only screen and (max-width: 640px){ //phones
        padding-bottom: 0;
    }

    
    
    div.grid-container{
        row-gap: standard-margin();

        div:nth-child(1){
            grid-column: 2/12;
        }


        .case-study-card{

            img{
                width: 100%;
            }

            position: relative;

            div.overlay{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: hsl( var(--clr-orange-6) / 85%);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                p{
                    font-family: "Mukta";
                    font-weight: 300;
                    font-size: rhythm(2);
                    letter-spacing: 0.5rem;
                    text-transform: lowercase;
                    font-variant: small-caps;
                    color:hsl( var(--clr-blue-1));
                
                }

            }

            &:nth-child(even){
                grid-column: 2/7;
                @media only screen and (max-width: 768px){
                    grid-column: 2/12;
                }
                @media only screen and (max-width: 640px){
                    grid-column: 1/13;
                }
            }
            &:nth-child(odd){
                grid-column: 7/12;
                @media only screen and (max-width: 768px){
                    grid-column: 2/12;
                }
                @media only screen and (max-width: 640px){
                    grid-column: 1/13;
                }
    
            }
        }
    }
}

section#past-clients{
    div.grid-container{
        padding: standard-margin();
        .header {
            grid-column: 1/13;
            
        }

        .sector{
            h3{
                text-align: center;
            }
        }

        .past-clients{
            grid-column: 1/13;
            @include standard-grid;
            padding: 0;
            @media only screen and (min-width: 769px){ 
                div:nth-child(3n+1){
                    grid-column: 1/5;
                }
                div:nth-child(3n+2){
                    grid-column: 5/9;
                }
                div:nth-child(3n+3){
                    grid-column: 9/13;
                }
            }
            @media only screen and (max-width: 768px){ 
                div:nth-child(odd){
                    grid-column: 1/7;
                }
                div:nth-child(even){
                    grid-column: 7/13;
                }
            }
            @media only screen and (max-width: 640px){ 
                div:nth-child(n){
                    grid-column: 1/13;
                }
            }
            

        }
    }
}

section.my-story-chapter{
    

    div.grid-container{
        h2{ grid-column: 2/12; }
        div { 
            grid-row: 2/3;
           
        }
        img { 
            grid-row: 2/3;
            width: 100%;
            margin-top: calc( standard-margin() /2 );
         }
    }

    &:nth-child(3){
        padding: standard-margin() 0;
    }

    &:nth-child(odd){
        div.grid-container{
            div { grid-column: 2/8; }
            img { grid-column: 8/12; }
        }
    }
    &:nth-child(even){
        div.grid-container{
            h2{ text-align: right;}
            div { grid-column: 6/12; }
            img { grid-column: 2/6; }
        }
    }

    @media only screen and (max-width: 768px){ 
        &:nth-child(n){
            div.grid-container{
                h2{ text-align: center;}
                div { 
                    grid-column: 2/12; 
                    grid-row: 3/4; 
                }
                img { 
                    grid-column: 2/12;
                    grid-row: 2/3;
                 }
            }
        }
    }
}