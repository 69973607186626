@use "fonts";
@use "typography";
@use "components";
@use "hamburger";
@use "home";
@use "posts";
@use "composable_styles";
@import "scss_library";





:root {
  --clr-blue-1: 194 5% 95%;
  --clr-blue-2: 194 10% 80%;
  --clr-blue-4: 194 20% 50%;
  --clr-blue-5: 194 25% 35%;
  --clr-blue-6: 194 33% 16%;
  --clr-orange-1: 20 20% 3%;
  --clr-orange-3: 20 44% 19%;
  --clr-orange-4: 20 45% 27%;
  --clr-orange-5: 20 75% 30%;
  --clr-orange-6: 20 82% 45%;
  --clr-orange-7: 20 82% 59%;
  // Below this was template defaults, delete when ready
  --body-background: #f2f2f2;
  --body-color: #444;
  --heading-color: black;
  --action-color: #d64045;
}

body {
  background: var(--clr-blue-1);
  max-width: 1920px;
  margin: 0 auto;
}


main {
  padding: 0;
}

div.hero-image{
  background-size: cover;
  @include standard-grid;
  padding: 0;
  picture {
    grid-column: 1/13;
    img{
      grid-column: 1/13;
      width: 100%;
      max-width: 100%;
    }
  }
}

div.banner-image{
  padding: 0;
  picture{
    width: 100%;
    img {
      width: 100%;
    }
  }
}

section{
  div.section-hero-image{ 
    @include standard-grid;
    picture{
      grid-column: 1/13;
      margin-bottom: standard-margin();
      img {width: 100%; height: 100%;}

      @media only screen and (max-width: 640px){ //phone (landscape)
        margin: 0;
      }
    }

  }

}

div.grid-container{
  @include standard-grid;
  padding-bottom: standard-margin();
    @media only screen and (max-width: 640px){ //phone (landscape)
      padding: 0;
    }
  
}

section.page-introduction{
  background-color:hsl( var(--clr-blue-2));
  padding: standard-margin() 0;

  div.grid-container > div.image {
    img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
      margin-top: 1rem;
    }
    grid-column: 2/5;
  } 
  div.grid-container > div {
    grid-column: 5/11;
  }

  // div.grid-container > div {
  //     grid-column: 4/10;
  // }
    
  

  @media only screen and (max-width: 1366px){ //tablet  (landscape)
    div.grid-container > div.image {
        img {
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
          margin-top: 1rem;
        }
        grid-column: 2/5;
    }
    div.grid-container > div {
        grid-column: 5/11;
    }
  }

  @media only screen and (max-width: 768px){ 
    div.grid-container > div.image {
      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        margin-top: 1rem;
      }
      grid-column: 4/10;
  }
  div.grid-container > div {
      grid-column: 2/12;
  }
  }
}

section.post-introduction{
  background-color:hsl( var(--clr-blue-2));
  padding: standard-margin() 0;

  div.grid-container > div {
      grid-column: 4/10;
  }
    
  @media only screen and (max-width: 1366px){ //tablet  (landscape)
    div.grid-container > div {
        grid-column: 3/11;
    }
  }
}

section.primary-section{
  padding: standard-margin() 0;
  background-color: hsl( var(--clr-blue-5));
  color:hsl( var(--clr-blue-1));



  .primary{
    padding: standard-margin();
    color:hsl( var(--clr-blue-6));
    background-color:hsl( var(--clr-blue-2));
    margin-bottom: standard-margin();   
    span.keyword{
      color:hsl( var(--clr-orange-5))
    }


  }

  .secondary{
    background-color:hsl( var(--clr-blue-1));
    color:hsl( var(--clr-blue-6));
    padding: standard-margin();
  }
}

section.additional-information{
  padding: standard-margin() 0;
  background-color: hsl( var(--clr-blue-2));
  color:hsl( var(--clr-blue-6));

  @media only screen and (max-width: 640px){ //phone (landscape)
    padding: 0;
  }

}



footer {
  padding: standard-margin() 0;
  background-color: hsl( var(--clr-blue-6));

  h2 {
    font-size: rhythm(2);
    margin-bottom: standard-margin();
  }

  .wordmark{
    grid-column: 1/7;
    flex-direction: row;
    margin-bottom: standard-margin();
    text-decoration: none;
  }

  .contact, .content{
    color: hsl( var(--clr-blue-1));
    text-align: center;
  }

  .contact{
    grid-column: 1/5;    
  }
  
  .content{
    grid-column: 5/9;
  }

  .cta {
    display: none;
    grid-column: 9/13;
    background: orange;
    margin: 0 2rem;
  }
  .legal{
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid hsl( var(--clr-blue-1));
    color: hsl( var(--clr-blue-1));
    
    grid-column: 1/13;
    // background: yellow;
  }

  @media only screen and (max-width: 768px) { //tablet (portrait)
    .wordmark, .contact, .content, .cta{
      grid-column: 1/13;;
      text-align: center;
    }

    .legal{
      grid-column: 2/12;
    }
  }
  

}





















a {
  color: hsl( var(--clr-orange-7));
  text-decoration: underline;
  text-decoration-color:hsl( var(--clr-orange-6));
}







hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}
