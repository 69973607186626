$hamburger-layer-color:hsl( var(--clr-blue-1));

@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "scss_library";

body > nav {
    background: hsl( var(--clr-blue-6));
    color: hsl( var(--clr-blue-1));
    padding: 0;
    margin: 0;    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    min-height: 4rem;
    
    

    .navigation{
        margin: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;     
    }

    a {
        text-decoration: none;
        @media only screen and (max-width: 768px){ 
            font-size: rhythm(1);
            letter-spacing: rhythm(0.1125);
        }
    }

    .wordmark{
        
        flex-direction: column;
        justify-content: center;        
    }

    .nav-menu-items{
        text-decoration: none;
        font-family: "Mukta";
        font-weight: 200;
        text-transform: uppercase;
        font-size: rhythm(1.25);
        letter-spacing: rhythm(0.2);
        color:hsl( var(--clr-blue-1));   

        display: flex;
        flex-direction: column;
        justify-content: center;
        -webkit-transition: max-height 1.3s ease-out;
        transition: max-height 1.3s ease-out;
        ol {
            padding-inline-start: 0px;
            margin: 0px;
            list-style-type: none;
            display: flex;
            justify-content: center;
            gap: 1.5rem;
            font-size: 1.3rem;
            font-weight: bold;

            li{
                position: relative;
                
                span{
                    cursor: pointer;
                }
                svg.icon{
                    height: rhythm(1.25);
                    width: rhythm(1.25);
                    fill:hsl( var(--clr-blue-1));
                    margin-left: rhythm(-0.5);

                    &.up-chevron{
                        display: none;
                    }

                    &.right-chevron{
                        height: rhythm(1);
                        width: rhythm(1);
                        margin-left: rhythm(-0.25);
                    }
                }

                a {
                    color: hsl( var(--clr-blue-1));
                }

                &.open{
                    ol{display: flex; }
                    span{
                        text-decoration: underline;
                    }

                    svg.down-chevron{ display: none;}
                    svg.up-chevron{ display: inline;}
                }

                ol {
                    display: none;
                    background: hsl( var(--clr-blue-6));
                    color: hsl( var(--clr-blue-1));
                    flex-direction: column;
                    position: absolute;
                    top: rhythm(2);
                    right: calc(-1 * standard-margin() / 2);
                    width: 15rem;
                    padding: calc(standard-margin() / 2) standard-margin();
                    font-size: rhythm(1);
                    text-align: right;
                    a {
                        color: hsl( var(--clr-blue-1));
                    }
                }
            }    
        }
    }

    .hamburger{
        display: none;
        .description{
            display: none;
        }
    }
    
    @media only screen and (max-width: 640px){ //phones

        .navigation {
            position: relative;
            flex-direction: column;
            gap: 1rem;

            .wordmark{
                // flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: space-around; 
                
            }

            .nav-menu-items > ol{
                display: flex;
                flex-direction: column;
                gap: 1rem;

                svg.right-chevron{
                    fill:hsl( var(--clr-blue-6));
                }
                li {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    background-color: hsl( var(--clr-blue-1));
                    color: hsl( var(--clr-blue-6));
                    padding: rhythm(1) 0;
                    ol{
                        display: flex;
                        position: unset;
                        top: unset;
                        right: unset;
                        margin: 0 auto;
                        text-align: center;
                        padding-top: rhythm(1);
                        background-color: hsl( var(--clr-blue-1));
                        padding-bottom: 0;
                        
                        li{ padding: unset;}
                        a{
                            color: hsl( var(--clr-blue-6));
                        }
                        gap: rhythm(0.5);
                        // a{
                        //     color: hsl( var(--clr-blue-2));
                        // }
                      
                    }
                }

                svg.up-chevron, svg.down-chevron { display: none}
                span{
                    font-size: rhythm(1.25);                    
                    text-align: center;

                    &>a{
                        font-size: rhythm(1.25);
                        color: hsl( var(--clr-blue-6));
                    }

                }
            }
        }

        .hamburger{
            display: inline-block;
            position: absolute;
            top: 0.9rem;
            right: 0.5rem;
        }

        &.hamburger-closed{
            .nav-menu-items {
                max-height: 0px;
                // transform:scaleY(0);
                // flex: 0;
                display: none;
            }                   
        }

        &.hamburger-open{
            .nav-menu-items {
                max-height: 602px;
                display: flex;
                
                gap: 1rem;
                // flex: 1;
                // transform:scaleY(1);
            }                   
        }

    }

    @media only screen and (max-width: 375px){ //phones
        .wordmark {
            span{
                font-size: 1.5rem;
                padding-right: 1rem;
            }
        }
    }
  }
  