@import "scss_library";

body{
    // font-family: "IBM Plex Sans";
    font-family: "Nunito Sans";
    font-weight: 300;
    font-size: 1rem;
    // line-height: 1.5;
    // letter-spacing: rhythm(0.03);
    color: hsl( var(--clr-orange-1))
}

P {
  margin-block-start: calc( standard-margin()/ 2 );
  margin-block-end: calc(standard-margin()/ 2 );
}

h1 span.keyword,
h2 span.keyword,
h3 span.keyword,
p span.keyword{
  color:hsl( var(--clr-orange-6))
}

h1{
   height: 16rem;
  font-family: "Mukta";
  font-weight: 200;
  text-transform: lowercase;
  font-variant: small-caps;
  letter-spacing: 0.5rem;
  font-size: 4rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  @media only screen and (max-width: 1366px){ //tablet (landscape)
    font-size: 2rem;
    height: 8rem;
  }

  @media only screen and (max-width: 768px) { //tablet (portrait)
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
    height: 6rem;
  }

  @media only screen and (max-width: 640px) { // phone
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    height: 4rem;
  }

  @media only screen and (max-width: 375px) { // x-small phone
    font-size: 1rem;
    letter-spacing: 00.1rem;
    height: 4rem;
  }
}

h2{
  margin: 0 0;
  margin-top: rhythm(2);
  font-family: "mukta";
  font-weight: 300;
  font-size: rhythm(2);
  letter-spacing: 0.1rem;
  line-height: rhythm(1.5);
  text-transform: lowercase;
  font-variant: small-caps;

  @media only screen and (max-width: 768px) { //tablet (portrait)
    font-size: rhythm(1.5);
    // letter-spacing: rhythm(0.5);
  }
}


h3{
  font-size: rhythm(1.25);
  letter-spacing: rhythm(0.2);
  font-weight: 200;
  margin-bottom: 0;
}

p.callout{
  font-size: rhythm(1.25);
  

  @media only screen and (max-width: 768px) { //tablet (portrait)
    font-size: rhythm(1);
    font-weight: 400;
    font-style: italic;
  }

  @media only screen and (max-width: 640px) { //tablet (portrait)
    font-size: rhythm(0.75);
    letter-spacing: normal;
  }
  @media only screen and (max-width: 375px){
    font-size: rhythm(0.5);
  }
}

ul {
  margin: 0;
}