@import "scss_library";

#home-title-card{
  // aspect-ratio: 5 / 1;
  background:hsl( var(--clr-blue-1));

  div.flex-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div#ly-title{

    div.text{   
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;;    
      text-align: center;
      padding: 0 rhythm(1);
      span{
          display: block;
      }
    }
  }

  picture{
    height: fit-content;
    
    img {
      vertical-align: middle;
    }
  }

  
    h2{
      line-height: rhythm(1);
    }

    h3{
      @media only screen and (max-width: 768px) { //tablet (portrait)
        font-size: rhythm(1);
        letter-spacing: rhythm(0.2);
        margin: rhythm(0.5) 0;
      }
    
      @media only screen and (max-width: 640px) { //tablet (portrait)
        font-size: rhythm(0.75);
        letter-spacing: rhythm(0.1);
        margin: rhythm(0.5) 0;
        margin: 0;
      }
    }

    p.callout{
      margin-bottom: 0;
    
    }


  @media only screen and (max-width: 640px){ //tablet (landscape)
    picture{
      margin: rhythm(0.5);
    }
  }
  @media only screen and (min-width: 640px){ //tablet (landscape)
    div.flex-container{
      @include standard-grid;
    }
    div#ly-title{
      grid-column: 1/10;
    }

    picture{
      grid-column: 10/13;
      padding: standard-margin() 0;
      img{
        height: 100%;
        width: 100%;
      }
    }
  }

}


#home-main-copy{
  @media only screen and (max-width: 640px){ //phone (landscape)
    padding: 0;
  }

  div.grid-container{
    

    h2 {
      @media only screen and (min-width: 1366px){ //tablet (landscape)
      span.keyword{
        display: block;
      }
      }
    }

    div{
      &.business-meets-tech{
        grid-column: 1/7;      
        margin-bottom: 0;
      }
  
      &.learning-together{
        grid-column: 7/10;
      }
  
      &.discover-the-future{
        grid-column: 10/13;
      }
    }

    @media only screen and (max-width: 1366px){ //tablet (landscape)
      div.business-meets-tech{
        grid-column: 1/13; 
        margin-bottom: standard-margin();  
        
      }
  
      div.learning-together{
        grid-column: 1/7;
      }
  
      div.discover-the-future{
        grid-column: 7/13;
      }
    }

    @media only screen and (max-width: 640px){ //phone (landscape)

      h2 {
        span.keyword{
          display: inline;
        }
      }
      div.business-meets-tech{
        grid-column: 1/13;   
        margin-bottom: 0;
      }
  
      div.learning-together{
        grid-column: 1/13;
      }
  
      div.discover-the-future{
        grid-column: 1/13;
      }
    }
    
  }

}
